import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout.pt";
import SEO from "../components/seo";
import ContactFormPT from "../components/contactForm.pt";
import { WOW } from "wowjs";
import Wave from "react-wavify";
import Swiper from "react-id-swiper";
import SlickSlider from "react-slick";
import moment from "moment";
import MainVideo from "../../static/video/output.mp4";

const swiperParams = {
    pagination: {
        el: ".swiper-pagination",
        type: "custom",
        renderCustom: function(e, t, i) {
            var a = (100 / i) * t;
            // eslint-disable-next-line no-useless-concat
            return (a = a.toFixed(6)), '<div className="progressbar"><div className="filled" data-width="' + a + '" style="width: ' + a + "%" + '"></div></div>'; // eslint-disable-line no-sequences
        },
    },
    breakpoints: {
        640: {
            slidesPerView: 1,
        },
        1025: {
            slidesPerView: 4,
        },
    },
    spaceBetween: 20,
    autoplay: 0,
    loop: false,
    slidesPerView: 1,
    loopedSlides: 9,
    autoplayDisableOnInteraction: true,
};

class IndexPage extends Component {
    componentDidMount() {
        const wow = new WOW({
            boxClass: "wow",
            animateClass: "animate",
            offset: 100,
            mobile: false,
            live: true,
        });
        wow.init();
    }

    shortify = text => {
        const latinMap = {
            á: "a",
            à: "a",
            ã: "a",
            â: "a",
            ç: "c",
            é: "e",
            ê: "e",
            í: "i",
            ó: "o",
            õ: "o",
            ô: "o",
            ú: "u",
        };
        return text
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\-a-z0-9]/g, x => latinMap[x] || x)
            .replace(/[^\-a-z0-9]/g, "");
    };

    render() {
        const { data } = this.props;

        return (
            <Layout>
                <SEO title="Jamstack Developers em Lisboa" description="A Cottonhat é uma agência web, em Lisboa, que utiliza as tecnologias mais recentes para desenvolver soluções personalizadas" lang="pt" />
                <div className="bk-about-area section-ptb-100 mt-5" id="section-about">
                    <div className="container">
                        <div className="row about--creative align-items-center">
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="thumb text-left text-lg-left wow move-up">
                                    <Img fluid={data.heroImage.childImageSharp.fluid} style={{ maxHeight: 671, maxWidth: 532 }} alt="Cottonhat web studio portugal lisboa" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12 mt_md--40 mt_sm--40">
                                <div className="content pl--80 pl_md--5 pl_sm--5">
                                    <div className="title--creative wow move-up">
                                        <h2>
                                            Rápido e seguro
                                            <br />
                                            <span className="theme-creative">JAMstack</span> developers
                                        </h2>
                                    </div>
                                    <p className="wow move-up">Transforme a sua empresa com tecnologias web modernas e melhores práticas</p>
                                    <Link to="/pt/contacte-nos" className="about-btn wow move-up">
                                        <span>Trabalhe connosco</span>
                                        <i class="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="brook-what-do-area ptb--160 ptb-md--80 ptb-sm--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-2">
                                <div className="brook-section-title wow move-up">
                                    <h5 className="heading heading-h5 theme-color">Os nossos serviços</h5>
                                    <div className="bkseparator--30"></div>
                                    <h3 className="heading heading-h3 line-height-1-42">
                                        Soluções web para
                                        <br />
                                        negócios de sucesso
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="row mt--20 mt_sm--5 mt_md--5">
                            <div className="col-lg-2">
                                <div className="what-do mt--40 mt_md--20 mt_sm--5">
                                    <div className="basic-separator line-3"></div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="what-do mt--40">
                                    <div className="content">
                                        <h5 className="heading heading-h5 theme-color wow move-up">Centrado no Cliente</h5>
                                        <div className="bkseparator--20"></div>
                                        <p className="bk_pra wow move-up">Colocamos os nossos clientes e a sua experiência em primeiro lugar, em tudo o que fazemos, focando no desenvolvimento de relacionamentos fortes e positivos.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 offset-lg-1">
                                <div className="what-do mt--40">
                                    <div className="content">
                                        <h5 className="heading heading-h5 theme-color wow move-up">Soluções personalizadas</h5>
                                        <div className="bkseparator--20"></div>
                                        <p className="bk_pra wow move-up">
                                            Uma grande parte da nossa missão é criar soluções personalizadas para o SEU negócio, adaptadas às suas necessidades e objetivos. Usando os padrões da indústria, abordagens modernas e open software, esforçamo-nos para alcançar o melhor compromisso entre
                                            design e funcionalidade.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="brook-video-area bg_color--1 pb--10 pb_md--80 pb_sm--60 bg-as-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-12">
                                <div className="video-with-thumb text-center move-up wow">
                                    <div className="thumb">
                                        <video autoPlay muted loop style={{ width: "100%" }}>
                                            <source src={MainVideo} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-12 mt_md--40 mt_sm--40">
                                <div className="video-content move-up wow">
                                    <h3 className="heading heading-h3 line-height-1-42">
                                        Soluções eficazes para
                                        <br /> todos os negócios
                                    </h3>
                                    <div className="bkseparator--25"></div>
                                    <p className="bk_pra">A nossa experiência é traduzida em vários setores: farmacêutico, retalho, fidelização, moda, cosmética, CRM / ERP e Publicidade. Conheça mais sobre os nossos projetos.</p>
                                    <div className="bkseparator--40"></div>
                                    <Link className="moredetails-btn yellow-color-2" to="/pt/servicos">
                                        <span>Mais detalhes</span> <i class="fa fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bk-testimonial-area section-ptb-xl bg_color--1 poss_relative pt_sm--0">
                    <div className="wavify-wrapper">
                        <Wave
                            className="wavify-item"
                            fill="rgba(245,245,245,0.5)"
                            style={{ top: 0 }}
                            paused={false}
                            options={{
                                height: 140,
                                amplitude: 80,
                                speed: 0.3,
                                points: 4,
                            }}
                        />
                        <Wave
                            className="wavify-item"
                            fill="#f5f5f5"
                            style={{ top: 0 }}
                            paused={false}
                            options={{
                                height: 140,
                                amplitude: 80,
                                speed: 0.2,
                                points: 3,
                            }}
                        />
                    </div>

                    <div className="container section-pt-xl">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-2">
                                <div className="brook-section-title wow move-up">
                                    <div className="bkseparator--30"></div>
                                    <h3 className="heading heading-h3 line-height-1-42">Feedback dos nossos clientes</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="testimonial-wrapper testimonial--horizontal testimonial--horizontal--active pagination-style-01 mt--80 wow move-up">
                        <Swiper className="testimonial-container swiper-wrapper" {...swiperParams}>
                            <div className="testimonial testimonial_style--1 swiper-slide">
                                <div className="content">
                                    <p>“A empresa Cottonhat é responsável pela criação do nosso site, temos um site moderno, actual, intuitivo e com muito bons conteúdos fotográficos. Com uma equipa de profissionais dedicados que prestam um serviço exemplar à Ruika, somos parceiros de sucesso.”</p>
                                    <div className="testimonial-info">
                                        <div className="clint-info">
                                            <h6>Rui Nascimento</h6>
                                            <span>Ruika Shoes</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-quote">
                                        <span className="fa fa-quote-right"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial testimonial_style--1 swiper-slide">
                                <div className="content">
                                    <p>“Sempre atentos às nossas necessidades, criaram o produto que nós pretendíamos.”</p>
                                    <div className="testimonial-info">
                                        <div className="clint-info">
                                            <h6>Ricardo Bastos</h6>
                                            <span>Rede 360</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-quote">
                                        <span className="fa fa-quote-right"></span>
                                    </div>
                                </div>
                            </div>
                        </Swiper>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>

                <div className="bk-portfolio-area">
                    <div className="black-bg-wrapper bg_color--3 section-pt-xl pb--240">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-2">
                                    <div className="brook-section-title wow move-up">
                                        <div className="bkseparator--30"></div>
                                        <h3 className="heading heading-h3 line-height-1-42" style={{ color: "#fff" }}>
                                            Saiba como criamos valor para os nossos clientes
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="portfolio-wrapper pl--150 pr--150 pl_lg--50 pr_lg--50 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30">
                        <div className="row row--0 space_dec--110">
                            <div className="col-lg-6">
                                <div className="portfolio portfolio_style--1 home-hover">
                                    <div className="thumb">
                                        <img src={`${data.cs.Cottonhat_casestudyList[0].photo.url}?w=810&h=810&fit=crop`} alt={data.cs.Cottonhat_casestudyList[0].title} />
                                    </div>
                                    <Link to={`/pt/caso-de-estudo/${data.cs.Cottonhat_casestudyList[0].title.pt_PT ? this.shortify(data.cs.Cottonhat_casestudyList[0].title.pt_PT) : data.cs.Cottonhat_casestudyList[0].uri}`}>
                                        <div className="port-overlay-info" style={{ backgroundColor: data.cs.Cottonhat_casestudyList[0].overlayColor }}>
                                            <div className="hover-action">
                                                <h3 className="post-overlay-title">
                                                    <Link to={`/pt/caso-de-estudo/${data.cs.Cottonhat_casestudyList[0].title.pt_PT ? this.shortify(data.cs.Cottonhat_casestudyList[0].title.pt_PT) : data.cs.Cottonhat_casestudyList[0].uri}`}>
                                                        {data.cs.Cottonhat_casestudyList[0].title.pt_PT ? data.cs.Cottonhat_casestudyList[0].title.pt_PT : data.cs.Cottonhat_casestudyList[0].title}
                                                    </Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row row--0">
                                    <div className="col-lg-12 wow move-up">
                                        <div className="portfolio portfolio_style--1 home-hover">
                                            <div className="thumb">
                                                <img src={`${data.cs.Cottonhat_casestudyList[1].photo.url}?w=810&h=405&fit=crop`} alt={data.cs.Cottonhat_casestudyList[1].title} />
                                            </div>
                                            <Link to={`/pt/caso-de-estudo/${data.cs.Cottonhat_casestudyList[1].title.pt_PT ? this.shortify(data.cs.Cottonhat_casestudyList[1].title.pt_PT) : data.cs.Cottonhat_casestudyList[1].uri}`}>
                                                <div className="port-overlay-info" style={{ backgroundColor: data.cs.Cottonhat_casestudyList[1].overlayColor }}>
                                                    <div className="hover-action">
                                                        <h3 className="post-overlay-title">
                                                            <Link to={`/pt/caso-de-estudo/${data.cs.Cottonhat_casestudyList[1].title.pt_PT ? this.shortify(data.cs.Cottonhat_casestudyList[1].title.pt_PT) : data.cs.Cottonhat_casestudyList[1].uri}`}>
                                                                {data.cs.Cottonhat_casestudyList[1].title.pt_PT ? data.cs.Cottonhat_casestudyList[1].title.pt_PT : data.cs.Cottonhat_casestudyList[1].title}
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 wow move-up">
                                        <div className="portfolio portfolio_style--1 home-hover">
                                            <div className="thumb">
                                                <img src={`${data.cs.Cottonhat_casestudyList[2].photo.url}?w=480&h=480&fit=crop`} alt={data.cs.Cottonhat_casestudyList[2].title} />
                                            </div>
                                            <Link to={`/pt/caso-de-estudo/${data.cs.Cottonhat_casestudyList[2].title.pt_PT ? this.shortify(data.cs.Cottonhat_casestudyList[2].title.pt_PT) : data.cs.Cottonhat_casestudyList[2].uri}`}>
                                                <div className="port-overlay-info" style={{ backgroundColor: data.cs.Cottonhat_casestudyList[2].overlayColor }}>
                                                    <div className="hover-action">
                                                        <h3 className="post-overlay-title">
                                                            <Link to={`/pt/caso-de-estudo/${data.cs.Cottonhat_casestudyList[2].title.pt_PT ? this.shortify(data.cs.Cottonhat_casestudyList[2].title.pt_PT) : data.cs.Cottonhat_casestudyList[2].uri}`}>
                                                                {data.cs.Cottonhat_casestudyList[2].title.pt_PT ? data.cs.Cottonhat_casestudyList[2].title.pt_PT : data.cs.Cottonhat_casestudyList[2].title}
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 wow move-up">
                                        <div className="portfolio portfolio_style--1 home-hover">
                                            <div className="thumb">
                                                <img src={`${data.cs.Cottonhat_casestudyList[3].photo.url}?w=480&h=480&fit=crop`} alt={data.cs.Cottonhat_casestudyList[3].title} />
                                            </div>
                                            <Link to={`/pt/caso-de-estudo/${data.cs.Cottonhat_casestudyList[3].title.pt_PT ? this.shortify(data.cs.Cottonhat_casestudyList[3].title.pt_PT) : data.cs.Cottonhat_casestudyList[3].uri}`}>
                                                <div className="port-overlay-info" style={{ backgroundColor: data.cs.Cottonhat_casestudyList[3].overlayColor }}>
                                                    <div className="hover-action">
                                                        <h3 className="post-overlay-title">
                                                            <Link to={`/pt/caso-de-estudo/${data.cs.Cottonhat_casestudyList[3].title.pt_PT ? this.shortify(data.cs.Cottonhat_casestudyList[3].title.pt_PT) : data.cs.Cottonhat_casestudyList[3].uri}`}>
                                                                {data.cs.Cottonhat_casestudyList[3].title.pt_PT ? data.cs.Cottonhat_casestudyList[3].title.pt_PT : data.cs.Cottonhat_casestudyList[3].title}
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bk-brand-area bg_color--1 ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="brand-wrapper">
                                    <div className="brand__list brand-default brand-style--1">
                                        <SlickSlider classNameName="brook-element-carousel" autoplay={true} spaceBetween={15} slidesToShow={5} slidesToScroll={1} arrows={false} infinite={true}>
                                            <div className="brand">
                                                <Link href="https://tswarriorplayer.com/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/tswarriorplayer_164x164.png" alt="Projeto e-commerce para tswarriorplayer feito por cottonhat lisboa portugal" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="https://play.google.com/store/apps/details?id=pt.homa.ecommerce" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/homa_164x164.png" alt="Aplicação móvel para hôma feito por cottonhat lisboa portugal" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/maisfarmacia_164x164.png" alt="Plataforma CRM para maisfarmacia feito por cottonhat lisboa portugal" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/bial_164x164.png" alt="Projeto para bial feito por cottonhat lisboa portugal" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/deborla_164x164.png" alt="Aplicação móvel para deborla feito por cottonhat lisboa portugal" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/loreal_164x164.png" alt="Projeto para loreal feito por cottonhat lisboa portugal" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/audi_v2_164x164.png" alt="Landing pages para audi feito por cottonhat lisboa portugal" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/bioderma_164x164.png" alt="CRM para bioderma feito por cottonhat lisboa portugal" />
                                                </Link>
                                            </div>
                                            <div className="brand">
                                                <Link href="https://hangus.pt/" target="_blank" rel="noreferrer">
                                                    <img style={{ width: 100 }} src="/images/clients/hangus_164x164.png" alt="Projeto e-commerce para hangus feito por cottonhat lisboa portugal" />
                                                </Link>
                                            </div>
                                        </SlickSlider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bk-blog-area">
                    <div
                        className="black-bg-wrapper bg_image--4 section-pt-xl pb--320"
                        style={{
                            backgroundImage: "url(/images/blog_bg.jpg)",
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-2">
                                    <div className="brook-section-title wow move-up">
                                        <div className="bkseparator--30"></div>
                                        <h3 className="heading heading-h3 line-height-1-42">Do nosso blog</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="blog-wrapper pl--230 pr--230 pr_lp--100 pl_lp--100 pl_lg--100 pr_lg--100 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30 section-pb-xl">
                        <div className="row row--0 space_dec--250">
                            {data.blog.Cottonhat_blogList.map(item => (
                                <div className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 wow move-up">
                                    <div className="blog blog-style--1">
                                        <div className="thumb">
                                            <Link to={`/pt/blog/${item.title.pt_PT ? this.shortify(item.title.pt_PT) : item.uri}`}>
                                                <img src={`${item.photo.url}?w=480&h=480&fit=crop`} alt="Blog Images" />
                                            </Link>
                                        </div>
                                        <div className="blog-content blog-position--bottom home-blog-block">
                                            <div className="content">
                                                <ul className="meta">{moment(item.creat_default.createdAt).format("LL")}</ul>
                                                <h3 className="the-title">
                                                    <Link to={`/pt/blog/${item.title.pt_PT ? this.shortify(item.title.pt_PT) : item.uri}`}>{item.title.pt_PT ? item.title.pt_PT : item.title}</Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bk-contact-area section-pb-xl bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-12">
                                <div className="address-inner">
                                    <div className="address wow move-up">
                                        <h3 className="heading">Visite-Nos</h3>
                                        <p>
                                            <Link href="https://goo.gl/maps/vm3gtyZzC6jQnVNR9" target="_blank" rel="noreferrer">
                                                Rua Margarida de Abreu N 13, Esc 3
                                            </Link>
                                            <br />
                                            Lisboa, Portugal
                                        </p>
                                    </div>
                                    <div className="address mt--60 mb--60 wow move-up">
                                        <h3 className="heading">Manter contato</h3>
                                        <p>
                                            <a href="mailto:info@cottonhat.net">info@cottonhat.net</a>
                                        </p>
                                        <p>
                                            <a href="tel:00351927344725">(+351) 308 814 283</a>
                                        </p>
                                    </div>
                                    <ul className="social-icon icon-size-medium text-dark text-left tooltip-layout move-up wow">
                                        <li className="facebook">
                                            <a href="https://www.facebook.com/cottonhat" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Facebook">
                                                <i className="fab fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li className="instagram">
                                            <a href="https://www.instagram.com/cottonhat_studio/" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Instagram">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li className="behance">
                                            <a href="https://www.behance.net/cottonhat" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Behance">
                                                <i className="fab fa-behance"></i>
                                            </a>
                                        </li>
                                        <li className="linkedin">
                                            <a href="https://www.linkedin.com/company/cottonhat/" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Linkedin">
                                                <i className="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                        {/* <li className="twitter">
                                            <a href="https://twitter.com/cottonhat" target="_blank" rel="noreferrer" className="link hint--bounce hint--top hint--theme" aria-label="Twitter">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-8 offset-xl-1 col-lg-9 col-12 mt_md--40 mt_sm--40">
                                <ContactFormPT />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default IndexPage;

export const query1 = graphql`
    query {
        blog: wbapi {
            Cottonhat_blogList(project: "592d4cf5b549b452a450d829", page: 1, limit: 3) {
                project_id
                title
                _id
                uri
                normalized_name
                creat_default {
                    createdAt
                }
                photo {
                    url
                    edition_options {
                        crop_options {
                            height
                            width
                            x
                            y
                        }
                    }
                }
            }
        }
        cs: wbapi {
            Cottonhat_casestudyList(project: "592d4cf5b549b452a450d829", page: 1, limit: 4) {
                project_id
                title {
                    en_EN
                    pt_PT
                }
                _id
                normalized_name
                overlayColor
                uri
                creat_default {
                    createdAt
                }
                photo {
                    url
                    edition_options {
                        crop_options {
                            height
                            width
                            x
                            y
                        }
                    }
                }
            }
        }
        heroImage: file(relativePath: { eq: "banner-1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 532, quality: 100) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    originalImg
                }
            }
        }
    }
`;
